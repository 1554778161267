export default function IconbShop() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M5.53751 20.4751H28.0375" stroke="black" strokeWidth="1.3" />
            <path
                d="M12.1 10.1626L12.1 11.6626C12.1 14.1479 14.1147 16.1626 16.6 16.1626V16.1626C19.0853 16.1626 21.1 14.1479 21.1 11.6626V10.1626"
                stroke="black"
                strokeWidth="1.3"
            />
            <rect x="5.25001" y="4.2501" width="22.7" height="22.7" rx="2.55" stroke="black" strokeWidth="1.3" />
        </svg>
    );
}
